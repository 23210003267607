// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";


h5 {
    font-size: 1.2rem;
    line-height: 1.55em;
    color: black !important;
    
}

.text-light {
    font-size: 1.2rem;
    line-height: 1.55em;
    color: white !important;
}

p {
    color: black !important;
}

.light-grey-bg {
    background-color: #f5f5f5;
}
